.title-bar {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    width: 100%;
    background-color: #ededed;
    height: 3.125rem;
    position: fixed;
    top: 0px;
    left: 0px;
    display: inline-block;
    font-family: Arial;
    text-align: left;
    box-shadow: 0 0.063rem 0.625rem grey;
}

    .title-bar-item {
        display: inline-block;
        color: black;
        text-decoration: none;
        text-align: center;
        height: 3.125rem;
        margin: 0.375rem 0 0 0.625rem;
    }
        .title-bar-button:hover {
            color: darkgray;
        }

.with-title-bar-content {
    padding: 0;
    margin: 3.75rem 0 0 0;
}
