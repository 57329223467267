.bbr-left {
    border-bottom-left-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
}

.bbr-right {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}

.bbr-top {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}

.bbr-bottom {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}
