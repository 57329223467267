.site-introduction {
    background-color: whitesmoke;
    padding: 0 3.75rem 0 3.75rem;
    font-family: 'Work Sans', sans-serif;
    font-style: italic;
    text-align: justify;
    text-justify: inter-word;
    border: grey 0.125rem solid;
    box-shadow: 0 0.063rem 0.625rem grey;
}

    .signature {
        text-align: right;
    }

.homepage-controls {
    margin: 1.875rem;
    display: block;
}

.btn-like-link {
    display: inline-block;
    background-color: #00e1ff;
    color: #000000;
    padding-top: 0.156rem;
    padding-bottom: 0.156rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    width: 12.5rem;
}

    .btn-like-link:hover {
        background-color: #8a8a8a;
    }
