.content {
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.lblError {
    color: red;
}

@import '~/css/pages/homepage.css';
@import '~/css/pages/login.css';
@import '~/css/components/titleBar.css';
@import '~/css/buttonBorderRadius.css';
